import * as Yup from 'yup';
const regExp = /^([^0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*)$/;
const mobilePhoneRegExp = /((^(08|09|06)\d{8}$))/;
const phoneRegExp = /((^(02|03|04|05|07)\d{7}$)|(^(08|09|06)\d{8}$))/;

const getEmail = () =>
  Yup.string()
    .matches(
      /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      'common:validation.invalid_email'
    )
    .email('common:validation.invalid_email')
    .required('common:validation.required');

const getText = () =>
  Yup.string()
    .trim()
    .matches(regExp, {
      message: 'common:validation.invalid_name',
    })
    .required('common:validation.required');

const getPhone = (includeLandLine?: false) =>
  Yup.string()
    .matches(includeLandLine ? phoneRegExp : mobilePhoneRegExp, {
      message: 'common:validation.invalid_phone',
    })
    .max(10)
    .required('common:validation.required');

const validators: any = {
  text: getText(),
  email: getEmail(),
  phone: getPhone(),
};

export const getValidationSchemaQFlowInPage = (questions: any) => {
  const schema: any = {};

  questions.forEach((question: any) => {
    if (validators[question.type]) {
      schema[question.code] = validators[question.type]; // Add the corresponding validator
    }
  });
  return Yup.object().shape(schema);
};

export const formatPhoneNumber = (value: string) => {
  if (!value) {
    return '';
  }
  const landLinePrefix = ['02', '03', '04', '05', '07'];
  const startsWithSpecial = landLinePrefix.some((prefix) =>
    value.startsWith(prefix)
  );
  const delimiter = startsWithSpecial ? 2 : 3;
  if (value.length > 2 && value.length <= 6) {
    const regex = new RegExp(`(\\d{${delimiter}})(\\d{1,3})`);
    return value.replace(regex, '$1-$2');
  } else if (value.length > 6) {
    const regex = new RegExp(`(\\d{${delimiter}})(\\d{3})(\\d{1,4})`);
    return value.replace(regex, '$1-$2-$3');
  }
  return value;
};
